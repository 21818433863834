import React from "react";
import { Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { GetChassisList } from "../../services/ChassisServices";
import { CustomNoRowsOverlay } from "../../components/TableNoRowsOvlery";
import LoginIcon from "@mui/icons-material/Login";
import { setModelToChassisRegister } from "../../models/ChassisModel";
import Cookies from "universal-cookie";

import "./ChassisTable.css";

const ChassisTable = () => {
  const [yardOwners, setYardOwnerList] = React.useState({
    allRows: [],
    rows: [],
  });

  const cookies = new Cookies();
  const [fetchingData, setFetchingData] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState({
    show: false,
    row: {},
    handleFunction: (e) => handleCloseModal(),
  });

  function handleCopyLinkToClipBoard(row) {
    return function (e) {
      window.history.pushState(
        {},
        "",
        window.location.origin +
          "/yms-chassis-portal/chekcinout?CarrierScacCode=" +
          row.scac_rented +
          "&AgreementId=" +
          row.agreementId +
          "&Chassis=" +
          row.chassis_number
      );
    };
  }

  const allColumns = [
    {
      field: "actions",
      headerName: "Check In",
      minWidth: 90,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <GridActionsCellItem
          icon={<LoginIcon />}
          label="Edit"
          className="ChassisList-YardOwnerTable-CheckInButton"
          onClick={handleCopyLinkToClipBoard(params.row)}
        />
      ),
    },

    {
      field: "ownerChassis",
      headerName: "Owner SCAC Code",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "chassis_number",
      headerName: "Chassis Number",
      minWidth: 90,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "scac_rented",
      headerName: "Rented By",
      minWidth: 90,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "date_outgated",
      headerName: "Start Date",
      minWidth: 90,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];

  function handleCloseModal() {
    setModalInfo((modalInfo) => ({
      show: false,
      row: {},
      handleFunction: (e) => handleCloseModal(),
    }));
  }

  function handleFetchData() {
    setFetchingData(true);
    const sessionCosmos = cookies.get("sessionCosmos");
    if (!sessionCosmos) {
      window.addEventListener("userInB2C", () => {
        handleFetchData();
      });
      return;
    }
    GetChassisList(sessionCosmos.scac_code).then((data) => {
      const chassisList = setModelToChassisRegister(data.Data);
      setYardOwnerList({
        allRows: chassisList,
        rows: chassisList,
      });
      setFetchingData(false);
    });
  }

  React.useEffect(() => {
    handleFetchData();
  }, []);

  const getRowId = (row) => row.chassis_number;
  return (
    <>
      <section className="ChassisList-YardOwnerTable-container">
        <Typography
          variant="h5"
          className="ChassisList-YardOwnerTable-table-title"
        >
          Chassis List
        </Typography>
        <div className="ChassisList-YardOwnerTable-table-class">
          <DataGrid
            className="ChassisList-YardOwnerTable-table-class"
            getRowId={getRowId}
            rows={yardOwners.rows || []}
            columns={allColumns}
            disableSelectionOnClick
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,

              toolbar: GridToolbar,
            }}
            sx={{ "--DataGrid-overlayHeight": "300px" }}
            loading={fetchingData}
          />
        </div>
      </section>
    </>
  );
};

export default ChassisTable;
