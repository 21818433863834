import * as React from "react";
const TableRowContext = React.createContext();
export const useTableRowContext = () => React.useContext(TableRowContext);

export const TableRowProvider = ({ children }) => {
  const [showAllColumns, setShowAllColumns] = React.useState(false);
  const [search, setSearch] = React.useState("");

  function handleChangeShowAllColumns() {
    setShowAllColumns((showAllColumns) => !showAllColumns);
  }

  function setSearchValue(value) {
    setSearch(value);
  }

  return (
    <TableRowContext.Provider
      value={{
        showAllColumns,
        handleChangeShowAllColumns,
        search,
        setSearchValue,
      }}
    >
      {children}
    </TableRowContext.Provider>
  );
};
