import { axiosInstance } from "./appInsights";
const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};
export const GetChassisList = async (ScacCodeOwner) => {
  try {
    const config = {
      headers,
      url: process.env.REACT_APP_GET_RENTED_CHASSIS,
      method: "GET",
      params: {
        ScacCodeOwner,
      },
      nameEvent: "ChassisAvailableList-GetChassisList",
    };

    const response = await axiosInstance(config);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
