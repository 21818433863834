function returnFormattedDate(date) {
  //Aug 1, 2023
  if (!date) return "N/A";
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString("default", { month: "short" });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  return `${month} ${day}, ${year}`;
}

export function setModelToChassisRegister(chassisList) {
  return chassisList.map((chassis) => {
    return {
      agreementId: chassis.AgreementId ? chassis.AgreementId : "-- N/A --",
      type: chassis.Type ? chassis.Type : "-- N/A --",
      ownerChassis: chassis.ScacCodeOwner ? chassis.ScacCodeOwner : "-- N/A --",
      chassis_number: chassis.ChassisNumber
        ? chassis.ChassisNumber
        : "-- N/A --",
      scac_rented: chassis.CarrierScacCode
        ? chassis.CarrierScacCode
        : "-- N/A --",
      date_outgated: returnFormattedDate(chassis.StartDateTime),
    };
  });
}
